import PropTypes from "prop-types"

export const UserName = ({ user, variant = "full" }) => {
  return (
    <span>
      {user.fullName}
      {variant === "full" && user.organizationType
        ? ` (${user.organizationType.enum_label})`
        : null}
    </span>
  )
}

UserName.propTypes = {
  variant: PropTypes.oneOf(["full", "compact"]),
}
