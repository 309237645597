import { Text } from "components/Text"

export const LabelWithIcon = ({ label, icon }) => {
  return (
    <div className="flex items-center space-x-1 lg:space-x-2">
      <div className="shrink-0">{icon}</div>
      <Text variant="body2">{label}</Text>
    </div>
  )
}
