import * as ContactType from "enums/ContactType"
import { UserLock } from "components/Icon/UserLock"
import { UserCheck } from "components/Icon/UserCheck"
import { User, UserTimes } from "components/Icon"

export const ContactTypeIcon = ({ contactType, className }) => {
  let Component

  switch (contactType) {
    case ContactType.BLOCKED:
      Component = UserLock
      break

    case ContactType.NEW:
    case ContactType.IN_TOUCH:
      Component = UserCheck
      break

    case ContactType.EXTERNAL:
      Component = User
      break

    case null:
      Component = UserTimes
      break

    default:
      Component = Noop
      break
  }

  return <Component className={className} />
}

const Noop = () => null
