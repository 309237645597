import * as React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

export const InlineList = ({ children: childrenProps, separator }) => {
  const children = React.Children.toArray(childrenProps)
    .filter(Boolean)
    .map((child, index, array) => {
      return React.cloneElement(child, {
        className: cx(
          { "mr-1": index !== array.length - 1 && !separator },
          child.props.className,
          "my-1"
        ),
        children: (
          <>
            {child.props.children}
            {index !== array.length - 1 && separator ? (
              <Separator>{separator}</Separator>
            ) : null}
          </>
        ),
      })
    })

  return <ul className="-my-1">{children}</ul>
}

InlineList.propTypes = {
  separator: PropTypes.node,
}

InlineList.defaultProps = {
  separator: "•",
}

const Separator = ({ children }) => {
  return <span className="inline-block mx-1">{children}</span>
}

const Item = ({ children, className }) => {
  return (
    <li className={cx("inline-block max-w-full", className)}>{children}</li>
  )
}

InlineList.Item = Item
