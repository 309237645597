import cx from "classnames"
import PropTypes from "prop-types"
import { Text } from "components/Text"

export function Badge(props) {
  const { label, size, className, ...rest } = props

  const classNames = cx(
    "rounded",
    "border",
    "inline-flex",
    "border-primary-default",
    "text-primary-default",
    {
      "px-1": size == "small",
      "px-2 py-1": size == "big",
    },
    className
  )

  return (
    <Text
      variant={size == "big" ? "tag" : "caption"}
      className={classNames}
      {...rest}
    >
      {label}
    </Text>
  )
}

Badge.propTypes = {
  label: PropTypes.node.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(["small", "big"]).isRequired,
}
