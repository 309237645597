import { useTranslator } from "components/Translator"
import { StarRegular } from "components/Icon"
import { LabelWithIcon } from "components/LabelWithIcon"

export const UserNbReviews = ({ user }) => {
  const translator = useTranslator()

  if (user.nbReviews === null || user.nbReviews === undefined) {
    return null
  }

  return (
    <LabelWithIcon
      icon={<StarRegular className="w-4 text-primary-default" />}
      label={translator.trans(
        "UserInfos.reviews",
        { nbReviews: user.nbReviews },
        "components"
      )}
    />
  )
}
