import * as React from "react"
import { Text } from "components/Text"

const ListContext = React.createContext()

export const List = ({ children, marker }) => {
  return (
    <ListContext.Provider value={{ marker }}>
      <ul>{children}</ul>
    </ListContext.Provider>
  )
}

const Item = ({ children }) => {
  const context = React.useContext(ListContext)
  return (
    <div className="flex flex-row space-x-4 lg:py-2 items-start">
      <div>{context.marker}</div>
      <Text variant="body1">{children}</Text>
    </div>
  )
}

List.Item = Item
