import { useConnectedUser } from "components/ConnectedUserProvider"
import * as React from "react"
import { Modal, ModalContent } from "components/Modal"
import { Button } from "components/Button"
import { Text } from "components/Text"
import { Anchor } from "components/Icon/Anchor"
import { List } from "components/List"
import { useTranslator } from "components/Translator"

export const RequireLogin = ({ children }) => {
  const connectedUser = useConnectedUser()
  const translator = useTranslator()

  const [showConfirmModal, setShowConfirmModal] = React.useState(false)

  const handleUnauthenticatedInteraction = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setShowConfirmModal(true)
  }

  const events = ["onClick", "onSubmit"]
  const eventsToOverride = events.filter((event) => children.props[event])

  const overridenProps = eventsToOverride.reduce((prev, current) => {
    prev[current] = handleUnauthenticatedInteraction

    return prev
  }, {})

  if (connectedUser) {
    return children
  }

  return (
    <>
      {React.cloneElement(children, overridenProps)}
      <Modal
        isOpen={showConfirmModal}
        title={translator.trans("RequireLogin.modal.title", null, "components")}
        onRequestClose={() => setShowConfirmModal(false)}
      >
        <ModalContent>
          <div className="flex flex-col space-y-4 justify-between">
            <List marker={<Marker />}>
              <List.Item>
                {translator.trans(
                  "RequireLogin.modal.subtitle1",
                  null,
                  "components"
                )}
              </List.Item>
              <List.Item>
                {translator.trans(
                  "RequireLogin.modal.subtitle2",
                  null,
                  "components"
                )}
              </List.Item>
              <List.Item>
                {translator.trans(
                  "RequireLogin.modal.subtitle3",
                  null,
                  "components"
                )}
              </List.Item>
            </List>
            <Text className="text-center" variant="headline5">
              {translator.trans(
                "RequireLogin.modal.subtitle4",
                null,
                "components"
              )}
            </Text>
            <Button
              href={{
                route: "app_user_security_register",
              }}
              type="button"
              color="primary"
            >
              {translator.trans(
                "RequireLogin.modal.registerRedirection",
                null,
                "components"
              )}
            </Button>
            <Button
              href={{
                route: "app_user_security_login",
                params: { target_path: window.location.pathname },
              }}
              type="button"
              variant="outline"
            >
              {translator.trans(
                "RequireLogin.modal.loginRedirection",
                null,
                "components"
              )}
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </>
  )
}

const Marker = () => <Anchor className="w-4 pt-1 text-secondary-default" />
