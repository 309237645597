import baseFormatDistanceStrict from "date-fns/formatDistanceStrict"
import { enGB, fr } from "date-fns/locale"

const localeToDateFnsLocale = { en: enGB, fr: fr }

export function formatDistanceStrict(date, baseDate) {
  return baseFormatDistanceStrict(date, baseDate, {
    locale: localeToDateFnsLocale[window.__dateFnsLocale__],
  })
}
