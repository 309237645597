import { Badge } from "components/Badge"
import { useTranslator } from "components/Translator"
import * as PlanOrganizationScope from "enums/PlanOrganizationScope"

export const UserBadges = ({ user, size }) => {
  const translator = useTranslator()

  return (
    <>
      <UserTypeBadge user={user} size={size} />
      {user.roles.includes("ROLE_ADMIN") ? (
        <Badge
          label={translator.trans("UserBadges.admin", null, "components")}
          size={size}
        />
      ) : null}
    </>
  )
}

const UserTypeBadge = ({ user, size }) => {
  const translator = useTranslator()
  const label = translator.trans(getLabelKeyForUserType(user))

  return <Badge label={label} size={size} />
}

const getLabelKeyForUserType = (user) => {
  if (user.roles.includes("ROLE_CLUB")) {
    return getLabelKeyForClubMember(user)
  }

  if (user.roles.includes("ROLE_EX_CLUB")) {
    return "UserBadges.exClub"
  }

  if (user.roles.includes("ROLE_EXTERNAL")) {
    return "UserBadges.external"
  }

  return "UserBadges.observer"
}

const getLabelKeyForClubMember = (user) => {
  if (PlanOrganizationScope.ASSO === user.organizationScope?.enum_value) {
    return "UserBadges.club.asso"
  } else if (PlanOrganizationScope.ORG === user.organizationScope?.enum_value) {
    return "UserBadges.club.pro"
  }

  return "UserBadges.club.generic"
}
