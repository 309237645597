import { useTranslator } from "components/Translator"
import { LabelWithIcon } from "components/LabelWithIcon"
import { SuperNavigator } from "components/Icon"

export const UserSuper = ({ user }) => {
  const translator = useTranslator()

  if (!user.super) {
    return null
  }

  return (
    <LabelWithIcon
      icon={<SuperNavigator className="w-4" />}
      label={
        <div className={"line-clamp-1"}>
          {translator.trans("UserInfos.super", null, "components")}
        </div>
      }
    />
  )
}
