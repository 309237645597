import { ContactTypeIcon } from "domains/contact/ContactTypeIcon"
import { UserAvatar } from "domains/user/UserAvatar"
import { UserCertifiedTag } from "domains/user/UserCertifiedTag"
import { UserProfileCompletionRate } from "domains/user/UserProfileCompletionRate"
import PropTypes from "prop-types"
import { useConnectedUser } from "components/ConnectedUserProvider"
import { Text } from "components/Text"
import { useTranslator } from "components/Translator"
import * as UserStatus from "enums/UserStatus"
import { formatDistanceStrict } from "utils/date"
import { Anchor, Home, QuestionCircle } from "components/Icon"
import { GlobeEurope } from "components/Icon/GlobeEurope"
import { FFVLevelIcon } from "components/FFVLevelIcon"
import * as Civility from "enums/Civility"
import { useBreakpoints } from "hooks/useBreakpoints"
import { InlineList } from "components/InlineList"
import { UserBadges } from "domains/user/UserBadges"
import { RawLink } from "components/Link"
import { formatAddress } from "utils/formatAddress"
import * as ProjectContext from "enums/ProjectContext"
import { UserName } from "domains/user/UserName"
import { UserNbReviews } from "domains/user/UserNbReviews"
import { UserSuper } from "domains/user/UserSuper"
import { LabelWithIcon } from "components/LabelWithIcon"
import { hasAddressData } from "domains/address/model"

export const UserInfos = ({ user, variant, href }) => {
  const Component = getComponentForVariant(variant)

  return <Component user={user} href={href} />
}

const variants = {
  full: "full",
  essential: "essential",
  compact: "compact",
  minimal: "minimal",
  search: "search",
}

UserInfos.propTypes = {
  variant: PropTypes.oneOf(Object.values(variants)).isRequired,
}

const getComponentForVariant = (variant) => {
  switch (variant) {
    case variants.full:
      return UserInfosFull

    case variants.search:
      return UserInfosSearch

    case variants.essential:
      return UserInfosEssential

    case variants.compact:
      return UserInfosCompact

    case variants.minimal:
      return UserInfosMinimal

    default:
      throw new Error(`Unknown UserInfos variant: ${variant}`)
  }
}

const UserInfosFull = ({ user }) => {
  const connectedUser = useConnectedUser()
  const translator = useTranslator()
  const { lg } = useBreakpoints()

  return (
    <div className="space-y-2 lg:space-y-4">
      <div className="flex space-x-4 lg:space-x-8">
        <div className="flex flex-col items-center space-y-2 shrink-0">
          <UserAvatar user={user} size={lg ? "large" : "medium"} />
          <Text variant="caption" className="text-light">
            #{user.id}
          </Text>
        </div>
        <div className="space-y-2 grow">
          <div className="flex flex-row flex-wrap items-center space-x-2">
            <div className="lg:flex lg:space-x-2">
              <div className="flex items-center">
                <Text variant="headline3" className="mr-2 mb-1 text-dark">
                  <UserName user={user} variant="compact" />
                </Text>
                <RawLink
                  href={translator.trans(
                    "UserInfos.lastNameHelpUrl",
                    null,
                    "components"
                  )}
                  external
                >
                  <QuestionCircle className="w-5 text-primary-default" />
                </RawLink>
              </div>
              <div className="flex items-center space-x-2">
                <UserBadges user={user} size="big" />
              </div>
            </div>
          </div>
          <InlineList separator={null}>
            <InlineList.Item>
              <UserCertifiedTag user={user} />
            </InlineList.Item>
            <InlineList.Item>
              <Text tag="span" variant="caption" className="text-light">
                <UserProfileCompletionRate user={user} />
              </Text>
            </InlineList.Item>
          </InlineList>
          <Text variant="caption" className="text-light">
            <InlineList>
              {connectedUser ? (
                <InlineList.Item>
                  <ContactTypeIcon
                    contactType={getContactTypeBetweenUsers(
                      connectedUser,
                      user
                    )}
                    className="inline-block w-4 text-dark"
                  />
                </InlineList.Item>
              ) : null}
              <InlineList.Item className="text-dark">
                <UserOnlineStatus user={user} />
              </InlineList.Item>
              {user.clubAt ? (
                <InlineList.Item>
                  <UserClubAt user={user} />
                </InlineList.Item>
              ) : null}
              {user.createdAt ? (
                <InlineList.Item>
                  <UserCreatedAt user={user} />
                </InlineList.Item>
              ) : null}
            </InlineList>
          </Text>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-1 md:grid-cols-2 md:gap-2 text-dark">
        <UserPrimaryAddress user={user} />
        <UserSecondaryAddress user={user} />
        <UserPortAddress user={user} />
        <UserNbReviews user={user} />
        <UserCertifications user={user} />
        <UserSuper user={user} />
        <UserArea user={user} />
      </div>
    </div>
  )
}

const UserInfosSearch = ({ user, href }) => {
  const connectedUser = useConnectedUser()

  return (
    <div className="space-y-2 lg:space-y-4">
      <div className="flex space-x-4 lg:space-x-8">
        <div className="flex flex-col items-center space-y-2 shrink-0">
          <UserAvatar user={user} size="medium" />
          <Text variant="caption" className="text-light">
            #{user.id}
          </Text>
        </div>
        <div className="space-y-2 grow">
          <div className="flex flex-wrap items-center">
            <RawLink href={href}>
              <Text variant="headline3" className="mr-2 mb-1 text-dark">
                <UserName user={user} variant="compact" />
              </Text>
            </RawLink>
            <div className="flex items-center space-x-2">
              <UserBadges user={user} size="big" />
            </div>
          </div>
          <InlineList separator={null}>
            <UserCertifiedTag user={user} />
            <Text tag="span" variant="caption" className="text-light">
              <UserProfileCompletionRate user={user} />
            </Text>
          </InlineList>
          <Text variant="caption" className="text-light">
            <InlineList>
              {connectedUser ? (
                <InlineList.Item>
                  <ContactTypeIcon
                    contactType={getContactTypeBetweenUsers(
                      connectedUser,
                      user
                    )}
                    className="inline-block w-4 text-dark"
                  />
                </InlineList.Item>
              ) : null}
              {user.clubAt ? (
                <InlineList.Item>
                  <UserClubAt user={user} />
                </InlineList.Item>
              ) : null}
            </InlineList>
          </Text>
        </div>
      </div>
      <div className="flex flex-col lg:space-y-2 text-dark">
        <UserPrimaryAddress user={user} />
        <UserSecondaryAddress user={user} />
        <UserArea user={user} />
        <UserPortAddress user={user} />
        <UserNbReviews user={user} />
        <UserSuper user={user} />
        <UserOnlineStatus user={user} />
      </div>
    </div>
  )
}

const getContactTypeBetweenUsers = (currentUser, user) => {
  const contact = currentUser.contactDetails.find(
    (contact) => user.id === contact.user_id
  )

  if (!contact) {
    return null
  }

  return contact.type
}

const UserOnlineStatus = ({ user }) => {
  const translator = useTranslator()

  return user.status === UserStatus.ONLINE ? (
    <span>
      <span className="text-lg text-success-default">●</span>
      <span className="inline-block ml-1">
        {translator.trans("UserInfos.online", null, "components")}
      </span>
    </span>
  ) : user.lastActivityAt ? (
    <UserLastActivity user={user} component="span" />
  ) : null
}

const UserPrimaryAddress = ({ user }) => {
  const translator = useTranslator()
  const address = user.primaryAddress

  if (!hasAddressData(address)) {
    return null
  }

  return (
    <LabelWithIcon
      icon={<Home className="w-4 text-primary-default" />}
      label={
        user.civility?.enum_value === Civility.ORGANIZATION
          ? translator.trans(
              "UserInfos.headquartersAddress",
              {
                address: address.formatted ? address.formatted : address.search,
              },
              "components"
            )
          : address.search
          ? address.search
          : [address.city, address.state, address.countryNormalized]
              .filter(Boolean)
              .join(", ")
      }
    />
  )
}

const formatAreaRegional = (areaRegional) => {
  return `${areaRegional.name ? areaRegional.name + " - " : ""}${
    areaRegional.countryNormalized
  }`
}

const UserSecondaryAddress = ({ user }) => {
  const address = user.secondaryAddress

  if (!hasAddressData(address)) {
    return null
  }

  return (
    <LabelWithIcon
      icon={<Home className="w-4 text-primary-default" />}
      label={
        address.search
          ? address.search
          : [address.city, address.state, address.countryNormalized]
              .filter(Boolean)
              .join(", ")
      }
    />
  )
}

const UserPortAddress = ({ user }) => {
  const address = hasAddressData(user.portAddress) ? user.portAddress : null

  if (!address) {
    return null
  }

  return (
    <LabelWithIcon
      icon={<Anchor className="w-4 text-primary-default" />}
      label={address.search ? address.search : formatAddress(address)}
    />
  )
}

const UserCertifications = ({ user }) => {
  const certifications = user.certificationLevels?.filter(
    (certificationLevel) => certificationLevel.certified
  )

  if (!certifications) {
    return null
  }

  return certifications.map((certification) => {
    return (
      <LabelWithIcon
        key={certification.id}
        icon={
          <FFVLevelIcon
            level={certification.certificationLevel.level}
            className="w-4"
          />
        }
        label={certification.certificationLevel.name}
      />
    )
  })
}

const UserArea = ({ user }) => {
  if (!user.areaRegional && !user.areaGlobal) {
    return null
  }

  return (
    <LabelWithIcon
      icon={<GlobeEurope className="w-4 text-primary-default" />}
      label={
        user.areaGlobal
          ? user.areaGlobal.name
          : formatAreaRegional(user.areaRegional)
      }
    />
  )
}

const UserInfosEssential = ({ user }) => {
  return (
    <div className="space-y-2">
      <div className="flex space-x-4">
        <div className="flex flex-col items-center space-y-2 shrink-0">
          <UserAvatar user={user} size="medium" />
          <Text variant="caption" className="text-light">
            #{user.id}
          </Text>
        </div>
        <div className="space-y-2 grow">
          <div className="flex flex-wrap items-center">
            <Text variant="headline3" className="mr-2 mb-1 text-dark">
              <UserName user={user} variant="compact" />
            </Text>
            <div className="flex items-center space-x-2">
              <UserBadges user={user} size="big" />
            </div>
          </div>
          <InlineList separator={""}>
            <InlineList.Item>
              <UserCertifiedTag user={user} />
            </InlineList.Item>
            <InlineList.Item>
              <Text tag="span" variant="caption" className="text-light">
                <UserProfileCompletionRate user={user} />
              </Text>
            </InlineList.Item>
          </InlineList>
          <div className="space-y-1 text-dark">
            <UserPrimaryAddress user={user} />
            <UserPortAddress user={user} />
            <UserSuper user={user} />
          </div>
        </div>
      </div>
      <Text variant="caption" className="text-light">
        <InlineList>
          {user.clubAt ? (
            <InlineList.Item>
              <UserClubAt user={user} />
            </InlineList.Item>
          ) : null}
          <InlineList.Item>
            <UserCreatedAt user={user} />
          </InlineList.Item>
        </InlineList>
      </Text>
    </div>
  )
}

const UserClubAt = ({ user }) => {
  const translator = useTranslator()

  return (
    <span>
      {translator.trans(
        "UserInfos.membershipPeriod",
        {
          membershipPeriod: formatDistanceStrict(
            new Date(user.clubAt),
            new Date()
          ),
        },
        "components"
      )}
    </span>
  )
}

const UserCreatedAt = ({ user }) => {
  const translator = useTranslator()

  return (
    <span>
      {translator.trans(
        "UserInfos.registerDate",
        {
          registerDate: new Date(user.createdAt).toLocaleDateString(
            translator.locale
          ),
        },
        "components"
      )}
    </span>
  )
}

const UserInfosCompact = ({ user }) => {
  return (
    <div className="flex items-center space-x-4">
      <div className="flex flex-col items-center space-y-1 w-24 lg:w-32">
        <UserAvatar user={user} size="medium" />
        <div className="flex flex-wrap justify-center items-center space-x-1">
          <Text variant="headline6" className="text-center text-dark">
            <UserName user={user} variant="compact" />
          </Text>
          <div className="space-x-1">
            <UserBadges user={user} size="small" />
          </div>
        </div>
      </div>
      <div>
        <InlineList separator="">
          <div className="flex flex-col space-y-2">
            <InlineList.Item>
              <UserCertifiedTag user={user} />
            </InlineList.Item>
            <InlineList.Item>
              <Text tag="span" variant="caption" className="text-light">
                <UserProfileCompletionRate user={user} />
              </Text>
            </InlineList.Item>
          </div>
        </InlineList>
        <div className="space-y-1">
          <UserNbReviews user={user} />
          <UserSuper user={user} />
        </div>
      </div>
    </div>
  )
}

const UserInfosMinimal = ({ user }) => {
  return (
    <div className="flex items-center space-x-2">
      <div className="shrink-0">
        <UserAvatar user={user} size="small" />
      </div>
      <div className="flex flex-col justify-between space-y-2">
        <Text variant="headline6" className="text-dark">
          <UserName user={user} variant="compact" />
        </Text>
        <div className="flex flex-row justify-between items-center space-x-2">
          <UserBadges user={user} size="small" />
          <UserSuper user={user} />
        </div>
        <div className="flex flex-row justify-between items-center space-x-2">
          <UserNbReviews user={user} />
          <UserCertifiedTag user={user} />
        </div>
        <UserLastActivity user={user} />
      </div>
    </div>
  )
}

const UserLastActivity = ({ user, component: Component = "div" }) => {
  const translator = useTranslator()

  if (process.env.PROJECT_CONTEXT === ProjectContext.COBOATERS) {
    return null
  }

  if (!user.lastActivityAt) {
    return null
  }

  return (
    <Component>
      {translator.trans(
        "UserInfos.lastActivity",
        {
          lastActivity: formatDistanceStrict(
            new Date(user.lastActivityAt),
            new Date()
          ),
        },
        "components"
      )}
    </Component>
  )
}
