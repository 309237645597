import { FFVLevel1 } from "components/Icon/FFVLevel1"
import { FFVLevel2 } from "components/Icon/FFVLevel2"
import { FFVLevel3 } from "components/Icon/FFVLevel3"
import { FFVLevel4 } from "components/Icon/FFVLevel4"
import { FFVLevel5 } from "components/Icon/FFVLevel5"

export const FFVLevelIcon = ({ level, ...props }) => {
  switch (level) {
    case 1:
      return <FFVLevel1 {...props} />

    case 2:
      return <FFVLevel2 {...props} />

    case 3:
      return <FFVLevel3 {...props} />

    case 4:
      return <FFVLevel4 {...props} />

    case 5:
      return <FFVLevel5 {...props} />

    default:
      console.error(`No icon found for FFV level ${level}`)
      return null
  }
}
